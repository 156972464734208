import SiteHeader from '@components/shared/SiteHeader';

const Custom404 = () => {
  return (
    <>
      <SiteHeader title="404: This page could not be found" />
      <div className="mx-auto grid h-[70vh] w-10/12 place-items-center md:w-full">
        <section className="flex h-16 items-center justify-center">
          <h1 className="font-satoshi-medium font-medium  text-black/40">404</h1>
          <p className=" mx-4 h-full w-0.5 bg-gray-light"></p>
          <div>
            <h2 className="mb-2 text-sm text-red md:text-4xl">Page not found</h2>
            <p className="text-xs capitalize text-gray-4 md:text-base">
              please check url in address bar & try again
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Custom404;
